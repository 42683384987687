@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary-color: #390179; /* Set your primary color here */
  --secondary-color: #000000; /* Set your primary color here */
  --main-bg: #e9f0fe;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.listItem {
  animation: smoothSlideDown 0.5s ease-in-out;
}

@keyframes smoothSlideDown {
  0% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}
